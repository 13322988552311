import { useUser } from '~/composables/data/useUser'

export default defineNuxtRouteMiddleware(async () => {
  const supabaseUser = useSupabaseUser()
  const { user, suspense } = useUser()

  if (supabaseUser.value) {
    await suspense()
  }

  const localePath = useLocalePath()

  if (user.value) {
    return navigateTo(localePath('dashboard'))
  }
})
